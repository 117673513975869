import { Roboto } from "next/font/google";
import { createTheme } from "@mui/material/styles";

export const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
  variable: "--font-roboto",
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#4C6FFF",
    },
  },
  typography: {
    fontFamily: "var(--font-roboto)",
  },
});

export default theme;
